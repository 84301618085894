<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">도쿄/관동 지역의 매력적인 관광지와 인기 시설을 대상으로 한 가성비 있는 티켓입니다. 유효기간 내에 대상 시설 중에서 원하는 시설을 3곳 선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR코드를 제시하시면 간편하게 입장할 수 있습니다. </div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
          ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다. ● 이용 개시 가능일 : 구입일로부터 <span>90일간 </span><br>
          예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
          ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
          예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
          ● 이용 가능 시설 : 유효 기간(7일) 내 <span>3개까지</span><br>
          ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
          ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
          예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
          ● 미사용 시설은 환불되지 않습니다. <br>
          ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i">{{title}}</div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='버스 노선' && !contentItem.noColor">{{cItem}}<br></a>
                    <p class="gfwz" v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 주의사항特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hftokyo/kr/havefun_title-kr.png'),
      loadImg: require('@/assets/images/hftokyo/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hftokyo/グループ 573.png'),
          title: ['스몰월즈 TOKYO'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['Ariake, Koto Ku, Tokyo To, 135-0063','Japan 아리아케 물류센터'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.smallworlds.jp/ko/'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['천재지변 등으로 인해 비행기 및 열차가 지연되거나, 관광시설이 폐관한 경우에도 환불할 수 없습니다.'] },
                { cid: 2, text: ['입장권의 분실 및 도난된 경우, 재발행되지 않습니다.'] },
                { cid: 3, text: ['재입장은 불가능합니다.'] }
              ]
            }]
        },
        {
          id: 2,
          img: require('@/assets/images/hftokyo/グループ 574.png'),
          title: ['나리타 리무진버스 편도(E티켓)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['①나리타공항 제1터미널 1층로비(남쪽출구1)'] }, { cid: 2, text: ['②나리타공항 제2터미널 1층로비(남쪽출구)'] }, { cid: 3, text: ['③나리타공항 제3터미널 1층로비'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://webservice.limousinebus.co.jp/web/en/Top.aspx'] }]
            },
            {
              msgId: 3,
              title: '버스 노선',
              content: [{ cid: 1, text: ['https://www.tripellet.com/limousine/route_list.pdf'] }]
            },
            {
              msgId: 4,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['E티켓은 승차권이 아닙니다. 반드시 카운터에서 실권으로 교환해 주세요.'] },
                { cid: 2, text: ['노선에 따라 운행횟수가 다르므로, 사전에 운행상황을 확인해 주세요.'] },
                { cid: 3, text: ['버스는 일반도로(고속도로 포함)를 주행하므로, 평일 아침저녁의 교통체증 및 휴가 시즌 등, 도로사정에 따라 늦어질 수 있습니다.'] }
              ]
            }]
        },
        {
          id: 3,
          img: require('@/assets/images/hftokyo/グループ 575.png'),
          title: ['게이큐 미사키 마구로 티켓 당일투어'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['Takanawa, Minato Ku, Tokyo To, 108-0074, Japan','(시나가와역)'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.haneda-tokyo-access.com/kr/ticket/discount/maguro-day.html'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['교환한 승차권은 교환당일에 한해 유효합니다.'] },
                { cid: 2, text: ['교환 후에는 환불할 수 없습니다.'] },
                { cid: 3, text: ['가맹점포의 영업시간, 휴업일은 변경될 수 있습니다.이용 시에는 반드시 점포, 시설에 확인한 후 방문해 주세요.'] },
                { cid: 4, text: ['가맹점포가 혼잡할 수 있습니다. 그러한 경우에는 일본손님과 동일하게 대기해 주시기 바랍니다. 해당 티켓은 우선권 등이 아닙니다.'] },
                { cid: 5, text: ['가맹점포에서는 해당 메뉴, 상품이 품절된 경우 대체품으로 대응하고 있습니다. 사전에 양해 부탁드립니다.<br>미사키 마구토 티켓의 가맹점포 정보 등 자세한 정보는 여기에서 확인해 주세요.','<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="https://www.keikyu.co.jp/visit/otoku/otoku_maguro/">https://www.keikyu.co.jp/visit/otoku/otoku_maguro/</a>'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hftokyo/グループ 576.jpg'),
          title: ['무민밸리파크 입장권&Travel Pass (성인)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['1-28-1  Minamiikebukuro, Toshima-ku, Tokyo'] },{ cid: 2, text: ['세이부 철도 이케부쿠로 역'] },{ cid: 3, text: ['(세이부 이케부쿠로 관광 안내소)'] },{ cid: 4, text: ['특급권 판매소 창 너머'] },{ cid: 5, text: ['※"MOOMINVALLEY PARK"에서는 교환할 수 없습니다'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.seiburailway.jp/railways/tourist/korea/ticket/metsa.html'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['상품내용 : 세이부철도 1일 프리패스, 무민 밸리 파크<br>1일 입장권, 무민 밸리 파크까지의 왕복 버스<br>(한노역 출발 또는 히가시한노역 출발)'] },
                { cid: 2, text: ['티켓교환시에는 이용하시는 손님 전원의 여권을<br>제시할 필요가 있습니다.'] },
                { cid: 3, text: ['여권복사본 및 사진, 또는 재류카드로도 가능합니다.'] },
                { cid: 4, text: ['교환장소에서는 취소 및 변경할 수 없습니다.'] },
                { cid: 5, text: ['티켓 사용가능기간은 교환일로부터 2주일이내입니다.'] },
                { cid: 6, text: ['【수량한정특전】 특전 "핀 뱃지"는 200명 한정으로, 소진시 종료됩니다.'] }
              ]
            }
          ]
        },
        // {
        //   id: 5,
        //   img: require('@/assets/images/hftokyo/グループ 577.png'),
        //   title: ['KOBE FARBRETON 신주쿠 3000엔 쿠폰'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '티켓교환장소',
        //       content: [{ cid: 1, text: ['3-20-8 Shinjuku, Shinjuku City, Tokyo'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '공식 사이트',
        //       content: [{ cid: 1, text: ['https://kobe-farbreton.com/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '주의사항',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['3000엔 쿠폰을 이용할 수 있습니다.'] },
        //         { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
        //         { cid: 3, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다. '] }
        //         ]
        //     }
        //   ]
        // },
        {
          id: 6,
          img: require('@/assets/images/hftokyo/グループ 578.png'),
          title: ['노노토리 3000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '●노노토리 KOBE YAKITORI STAND 신주쿠',
                '3-20-8 Shinjuku, Shinjuku City, Tokyo',
                '●노노토리 니혼바시',
                '6F GEMS Nihonbashi, 1-9-19, Kajicho, Chiyoda City, Tokyo'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['노노토리 KOBE YAKITORI STAND 신주쿠'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/honten/'] },
                { cid: 3, text: ['노노토리 니혼바시'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/%e9%87%8e%e4%b9%83%e9%b3%a5-%e6%97%a5%e6%9c%ac%e6%a9%8b/'] }
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['3000엔 쿠폰을 이용할 수 있습니다.'] },
                { cid: 2, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                { cid: 3, text: ['다음 매장 중 한 곳을 선택해 주세요.<br>(노노토리 KOBE YAKITORI STAND신주쿠、 노노토리 니혼바시)'] },
                { cid: 4, text: ['가게 이용 시, 자릿세가 추가됩니다.'] },
                { cid: 5, text: ['만석으로 인해 이용하실 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다. '] },
                { cid: 6, text: ['계산 시 트래블콘텐츠 앱 화면을 보여주신 후, QR코드를 스캔하면 결제 금액의 10%를 포인트로 받을 수 있습니다. <br>다운로드 : <a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="http://onelink.to/xyx76x">http://onelink.to/xyx76x</a>'] },
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hftokyo/グループ 579.png'),
          title: ['도쿄 돔 시티 어트랙션즈 라이드5'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '3-20-8 Shinjuku, Shinjuku City, Tokyo'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.tokyo-dome.co.jp/ko/tourists/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 날짜에 따라 다를 수 있습니다.이용하실 때는 사전에 영업시간·운휴 정보 등을확인하시길 바랍니다.'] },
                { cid: 2, text: ['본 상품은 외국인 전용 티켓입니다. 여권을 지참하신후에 방문해 주세요. 일본 국적을 가진 분은 구매하실수 없습니다.'] },
                { cid: 3, text: ['티켓은 1인당 1장씩 이용해 주세요.  여러명이 이용할 경우, 인원수만큼의 티켓이 필요합니다.'] },
                { cid: 4, text: ['어트랙션마다 연령 규정 및 탑승 규정이 있습니다.'] },
                { cid: 5, text: ['SUPER SENTAI LAND 및 기간 한정 어트랙션은 대상외 어트랙션입니다.'] }
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hftokyo/JOYPOLIS.jpg'),
          title: ['도쿄 조이폴리스 패스포트 (입장권+어트랙션 자유 이용권) '],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒135-0091 도쿄도 미나토구 다이바 1-6-1 DECKS Tokyo Beach 3F∼5F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://tokyo-joypolis.com/language/kr/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 날짜에 따라 다를 수 있습니다. 이용하실 때는 사전에 영업시간・운휴 정보 등을 확인하시길 바랍니다.'] }
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFTokyo&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-tokyo-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
      .el-image {
        width: 100%;
      }
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
        /deep/.gfwz {
          span {
            color: #FBB03B;
          }
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>